<template>
  <div>
    <button @click="fetchData">Obtener Datos</button>
    <div v-if="data">
      <h2>Respuesta de la API:</h2>
      <pre>{{ data }}</pre>
    </div>
    <div v-if="error">
      <p>Error: {{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      data: null,
      error: null
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get('http://localhost:3000/api/test');
        this.data = response.data;
      } catch (error) {
        this.error = error.message || 'Error al cargar los datos';
      }
    }
  }
};
</script>
